var $ = require('jquery')
var ImageInInput = require('./image-in-input')

var urlsListPath = '/webhandle-page-editor/files/thumbnails/urls'
var fileUploadUrlPrefix = '/webhandle-page-editor/files/upload'
var thumbnailsPathPrefix = '/webhandle-page-editor/files/thumbnails/thumb'

var getPossibleImages = function(url, callback) {
	$.get(urlsListPath + url + "?fileType=image", function(data) {
		callback(null, data)
	});
}

var UploadableImage = function(inputControl, onComplete) {
	this.$inputControl = $(inputControl)
	var prefix = this.$inputControl.attr('data-image-dir')
	var self = this
	
	var img = new ImageInInput(self.$inputControl, getPossibleImages,
	function(data, name, dir) {
		if(!dir) {
			dir = prefix
		}
		$.ajax({
			url: fileUploadUrlPrefix + dir,
			type: 'POST',
			cache: false,
			data: {
				dataUrl: data,
				dataFilename: name
			}
		});
		
		var finalUrl = dir + (dir.endsWith('/') ? '' : '/') + name;
		
		self.$inputControl.val(finalUrl);
	}, prefix)
	self.img = img
	img.onComplete = onComplete
	img.render()
}

UploadableImage.prototype.cleanup = function() {
	this.img.cleanup()
}

module.exports = UploadableImage
