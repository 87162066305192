var $ = require('jquery')

var tri = require('tripartite').createBlank()
tri.parseTemplateScript(require('./browser.tmpl'))

var templates = tri.templates

var addStylesIfNeeded = function() {
	if($('#pic-browser-styles').length == 0) {
		$('head').append('<style type="text/css" id="pic-browser-styles">' +
		templates['picBrowserStyles']() +
		'</style>')
	}
}

let imageSizeExt = ['2x', 'quarter', 'half']

var PicBrowser = function(/*array*/ pictures, /*function*/ chosenCallback) {
	this.chosenCallback = chosenCallback
	this.renderObjs = []
	for(var i in pictures) {
		var location = pictures[i]
		if(typeof location == 'string') {
			var name = location.split('/').pop()
			var thumbnail
			var fileType
			if(name.toLowerCase().indexOf('.png') > -1 || name.toLowerCase().indexOf('.jpg') > -1 
			|| name.toLowerCase().indexOf('.jpeg') > -1 || name.toLowerCase().indexOf('.gif') > -1
			|| name.toLowerCase().indexOf('.webp') > -1) {
				thumbnail = location
				fileType = 'image'
			}
			else if(name.toLowerCase().indexOf('.') > -1) {
				thumbnail = '/webhandle-page-editor/img/document.png'
				fileType = 'document'
			}
			else {
				thumbnail = '/webhandle-page-editor/img/folder.png'
				fileType = 'folder'
			}
			let o = {
				thumbnail: thumbnail,
				fullSize: location,
				name: name,
				fileType: fileType
			}
			if(o.name.indexOf('.') > -1) {
				let parts = o.name.split('.')
				o.ext = parts.pop()
				
				let base = parts.join('.')
				for(let e of imageSizeExt) {
					if(base.endsWith('-' + e)) {
						o.sizeExt = e	
						o.basename = base.substring(0, base.length - (e.length + 1))
						break
					}
				}
				if(!o.basename) {
					o.basename = base
				}
			}
			else {
				o.basename = o.name
			}
			this.renderObjs.push(o)
		}
		else {
			this.renderObjs.push(location)
		}
	}
	
	let jsons = this.renderObjs.map(obj => {
		if(obj.ext == 'json') {
			return obj.basename
		}
		return null
	}).filter(item => !!item)
	
	this.renderObjs = this.renderObjs.filter(obj => {
		if(jsons.includes(obj.basename)) {
			if(obj.ext == 'json' || obj.sizeExt || obj.ext == 'webp') {
				return false
			}
			return true
		}
		return true
	})
	
	this.renderObjs.sort((one, two) => {
		if(one.fileType == 'folder' && one.fileType == two.fileType) {
			return 0
		}
		if(one.fileType == 'folder') {
			return -1
		}
		if(two.fileType == 'folder') {
			return 1
		}
		return 0
	})
}

PicBrowser.prototype.render = function(container) {
	addStylesIfNeeded()
	$(container).append(templates['picBrowserHolder']({images: this.renderObjs}))
	
	var self = this
	$(container).on('click', '.pic-holder', function(evt) {
		evt.preventDefault()
		if(self.chosenCallback) {
			self.chosenCallback(self.renderObjs[$(evt.target).closest('.pic-holder').index()])
		}
	})
}

module.exports = PicBrowser