var $ = require('jquery')
var Uploader = require('./pic-upload')
var PicBrowser = require('./pic-browser')
var Dialog = require('ei-dialog')

var tri = require('tripartite').createBlank()
tri.parseTemplateScript(require('./image-in-input.tmpl'))
var templates = tri.templates

var statusAttr = 'data-image-in-input-status'

var addStylesIfNeeded = function() {
	if($('#image-in-input-styles').length == 0) {
		$('head').append('<style type="text/css" id="image-in-input-styles">' +
		templates['imageInInputStyles']() +
		'</style>')
	}
}


var imageInInput = function(inputControl, imagesSource, newImagePost, imagePrefix) {
	this.$inputControl = $(inputControl)
	this.imagesSource = imagesSource
	this.newImagePost = newImagePost
	this.imagePrefix = imagePrefix
	
	// Originally this component took a function which returned a result.
	// To offer the ability to navigate directories, we need to callback.
	// To standardize how this function works, we'll convert the no argument
	// functions to a callback.
	if(imagesSource.length == 0) {
		this.imagesSource = function(url, callback) {
			callback(null, imagesSource())
		}
	}
}

function isTouchDevice() {
	var deviceAgent = navigator.userAgent.toLowerCase();
	var msGesture = window.navigator && window.navigator.msPointerEnabled && window.MSGesture;
	var hasTouchStart = ( "ontouchstart" in window );
	var touchSupport = msGesture || (navigator.msMaxTouchPoints > 0) || (window.DocumentTouch && document instanceof DocumentTouch);

	var isTouchDevice = touchSupport ||
	(deviceAgent.match(/(iphone|ipod|ipad)/) ||
	deviceAgent.match(/(android)/)  ||
	deviceAgent.match(/(iemobile)/) ||
	deviceAgent.match(/iphone/i) ||
	deviceAgent.match(/ipad/i) ||
	deviceAgent.match(/ipod/i) ||
	deviceAgent.match(/blackberry/i) ||
	deviceAgent.match(/bada/i));

	return isTouchDevice;
}

function joinPath(one, two) {
	if(one.charAt(one.length - 1) != '/') {
		one += '/'
	}
	return one + two
}


imageInInput.prototype.render = function() {
	if(this.$inputControl.attr(statusAttr) == 'processed') {
		return;
	}

	this.$inputControl.attr(statusAttr, 'processed')


	var $next = this.$inputControl.next()
	if($next.hasClass('image-browser-replacement')) {
		$next.remove()
	}


	addStylesIfNeeded()
	var self = this
	this.$widget = $(templates['imageReplacementWidget']())
	this.$inputControl.after(this.$widget)
	this.$inputControl.attr('type', 'hidden')
	var prefix = this.$inputControl.attr('data-image-dir') || '/'




	this.$widget.find('.change').click(function(evt) {
		evt.preventDefault()

		function drawChooser(dir) {
			self.imagesSource(dir, function(err, data) {
				self.browser = new PicBrowser(data, function(chosen) {
					if(chosen.fileType == 'folder') {
						self.diag.close()
						drawChooser(chosen.fullSize)
					}
					else {
						var fin = new Image()
						var $final = self.$widget.find('.image-holder').html(fin)
						fin.src = chosen.fullSize
						self.diag.close()
						self.$inputControl.val(chosen.fullSize)
						if(self.onComplete) {
							self.onComplete(chosen.fullSize)
						}
						self.uploader.cleanup()

					}
				})
				
				self.diag = new Dialog({
					title: 'Choose Image',
					body: function(container) { 
						$(container).append('<p>Choose an image or drag and drop a new image or paste an image (ctrl-v).</p>')
						var inputButtonText = "Choose a file"
						if(isTouchDevice()) {
							inputButtonText = "Take a picture"
						}
						$(container).append('<br/><label class="cameraButton">' + inputButtonText + ' <input type="file" class="take-picture" accept="image/*;capture=camera"><br/><br/>')
						self.browser.render(container)
						return ''
					},
					on: {
						'.btn-ok': function() {
							var fin = new Image()
							var $final = self.$widget.find('.image-holder').html(fin)
							fin.src = self.uploader.getImageData()
							let path = joinPath(dir, self.uploader.getImageName())
							self.$inputControl.val(path)
							self.newImagePost(self.uploader.getImageData(), self.uploader.getImageName(), dir)
							if(self.onComplete) {
								self.onComplete(path)
							}
							self.uploader.cleanup()
						},
						'.btn-cancel': function() {
						}
					}
				})
				self.diag.open()

				var $diagBody = $('.dialog-frame .body').last()
				self.uploader = new Uploader($diagBody, $diagBody)
				self.uploader.render()

			})
		}
		drawChooser(prefix)
	})
	this.$widget.find('.clear').click(function(evt) {
		evt.preventDefault()
		self.$inputControl.val('')
		self.$widget.find('.image-holder img').remove()
	})
	
	var fin = new Image()
	var $final = self.$widget.find('.image-holder').html(fin)
	fin.src = this.$inputControl.val()
	
	return this
}

imageInInput.prototype.cleanup = function() {
	this.$inputControl.attr('type', 'text')
	this.$widget.remove()
	return this
}

module.exports = imageInInput
